import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import {API, APIS, CONSTANTS} from './constants';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private downloadDietPlan = new Subject<any>();
  baseUrl:string;
  externalBaseUrl:string;
  isNew = CONSTANTS.isNewAPIs;
  constructor(private readonly httpClient:HttpClient, public plattform: Platform) { 
    this.baseUrl = API.BASEURL;
    this.externalBaseUrl = API.externalBaseUrl;
  }

  authenticateInternal(key:string,payload):Observable<any>{
    const url = this.baseUrl+API.authenticateInternal;
    console.log(url,`?key=${key}`,payload);
    
    return this.httpClient.post(url+`?key=${key}&authCode=${payload}`,{},{headers:{}});
  }

  platForm() {
    console.log(this.plattform);
    return this.plattform.is("mobileweb");
    //return this.plattform.is("ios");
  }
  downloadDietPlanFunc() {
    this.downloadDietPlan.next();
  }
  
  async getDietPlans(isDetox, date, country, recommended) {
    if (this.isNew) {
      country = country ? country : "IND";
      let reqBody = {
        date: date,
        // "refresh": true,
        customerId: await CONSTANTS.email,
        detox: isDetox,
      };
      if (isDetox) {
        let b2 = 1200,
          b3 = 1800,
          c2 = 500,
          c3 = 1000,
          b4 = recommended;
        recommended = Math.round(
          recommended - (c2 + ((c3 - c2) * (b4 - b2)) / (b3 - b2))
        );
        reqBody["dietPlanName"] = "Detox";
        reqBody["refresh"] = true;
        reqBody["recommendedCalories"] = recommended;
      }
      // if(isNew) reqBody["refresh"] = true;
      const url = APIS.refreshBaseUrl + "" + APIS.dietPlansDirect; //+ "&detox=" + isDetox;
      return this.httpClient.post(url, reqBody, {}).toPromise();
    } else {
      country = country ? country : "IND";
      const url =
        APIS.BASEURL +
        "" +
        APIS.dietPlans +
        "?date=" +
        date +
        "&country=" +
        country +
        "&detox=" +
        isDetox;
      return this.httpClient.get(url, {}).toPromise();
    }
  }

  externalRegistration(key:string,payload):Observable<any>{
    const url = this.baseUrl+API.authenticateExternal;
    console.log(url,`?key=${key}`,payload);
    
    return this.httpClient.post(url+`?key=${key}`,payload,{headers:{}});
  }
  externalToken(key:string):Observable<any>{
    const url = this.baseUrl+API.getTokenExternal;
    console.log(url);
    
    return this.httpClient.get(url+`?key=${key}`);
  }
  fetchUserDetail(email):Observable<any>{
    const url =  this.externalBaseUrl+API.fetchCustomerDetails;
    return this.httpClient.get(url+`?email=${email}`);
  }
  defaultData():Observable<any>{
   const url = this.baseUrl+API.defaultDetail;
    return this.httpClient.get(url);
  }
  getOnePlan():Observable<any> {
    const url = this.baseUrl + "" + API.getOnePlan;
    return this.httpClient.get(url, {});
  }
  getProfile():Observable<any> {
    const url = this.baseUrl + "" + API.profile;
    return this.httpClient.get(url, {});
  }
  //https://app.smartdietplanner.com:8444/initiateTransaction?txnAmount=1.00&currency=INR&custId=9867461050

  initialTransactions(reqPayload):Observable<any> {
    const url = API.BASEURL8444 + API.initiateTransaction+`?txnAmount=${reqPayload.amount}&currency=INR&custId=${reqPayload.custId}`;
    return this.httpClient.post(url,{});
  }

  getPaytmProfile(authCode):Observable<any> {
    const url = API.BASEURL8444 + API.getPaytmProfile+`?authCode=${authCode}`;
    return this.httpClient.get(url, {});
  }

  updateExpiryDate(reqBody) {
    return this.httpClient
      .post(API.BASEURL + API.updateExpiryDate, reqBody, {})
      .toPromise();
  }
}
