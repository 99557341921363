
export const API = {

    BASEURL8444:'https://app.smartdietplanner.com:8444/',
    BASEURL:'https://app.smartdietplanner.com/api/',
    externalBaseUrl:'https://app.smartdietplanner.com:8445/api/',
    authenticateExternal:'authenticateExternal',
    authenticateInternal:'authenticateInternal',
    getTokenExternal:'getTokenExternal',
    fetchCustomerDetails:'fetchCustomerDetails',
    defaultDetail: 'defaultDetail',
    profile: 'profile',
    getPaytmProfile:"getUserInfo",
    initiateTransaction: "initiateTransaction",

    dietCovidPlans: "v2/dietPlans",
    getOnePlan:"customer/getOnePlan", 
    habitMaster: "habitMaster",
    customerHabit: "customer/habits",
    createHabit: "customer/createHabit",
    updateHabit: "customer/updateHabit",
    deleteHabits: "customer/deleteCustomerHabit",
    getHabitsForUpdate: "customer/habitsForUpdate",
    gerWeightGraph: "customer/weightGraphData",
    optionsData: "options",
    optionsDataNew: "dietPlans/options",
    optionSelection: "addDietPref",
    optionSelectionNew : "customer/addDietPref",
    payment: "payment",
    getCouponList: "getCouponList",
    subscribePlanByCoupon: "customer/subscribePlanByCoupon",
    paymentConfirm: "payment/confirm",
    removePlan: "removePlan",
    updateWeight: "customer/updateWeight",
    googleFit:"https://www.googleapis.com/fitness/v1/users/",
    sendMail: "customer/sendEmail",
    addTnC:"addTnC",
    refresh:"api/dietPlan/refresh/options",
    refreshInternational:"api/dietPlan/refresh/option",
    fetchFood:"fetch/food" ,
    dietPlanTiming: "save/dietPlan/timings",
    fetchDietPlans:"fetch/dietplans" ,
    fetchHelp:"fetch/help",
    detoxStatus:"saveupdate/detox/dietPlan/status",
    updateExpiryDate:"update/payment/details",
    updateTargetWeight:"update/target/weight",
    updateCurrentWeigt:"update/current/weight",
    timings:"fetch/dietPlan/timings",
    getCouponListOffered: "getCouponList?isOffered=true",
    getRecipeOfTheDay: "fetch/countrywise/food/items?country=IND",
    lessThan100CaloriesFoodItem: "fetch/low/calories/foods",
    highProteinFoodItem :"fetch/high/protein/foods",
    healthyChoicesFoodItem :"fetch/healthy/choices",
    lessThan100SlotsFoodItem: "fetch/cust/diet/details",
    lessThan100SlotsFoodItemNew: "fetchCustDietDetails",
    updateCustDietPlan : "update/cust/dietplan",
    referralCode: "fetch/referral/email", //?code=RHsNM 
    referralUser: "save/cust/payment/referral/details",
    updateReferralUser: "update/cust/payment/referral/details",
    referralUserTransactions: "fetch/cust/payment/referral/transactions",
    ratings: "save/cust/ratings",
    getDietPreference: "getDietPreference",
    updateDietPref: "updateDietPref",
    fetchTodoList: "fetchTodoList",
    saveOrUpdateCustDailyTodo: "saveOrUpdateCustDailyTodo",
    saveHotLeads: "saveHotLeads",
    deleteHotLeads: "deleteHotLeads",
    getFoodPrefHistory: "getFoodPrefHistory",
    searchFoodItemByName: "searchFoodItemByName",
    updateDietPlan: "updateDietPlan",
    updateEatenFoodItems: "updateEatenFoodItems",
    postSaveFastingDetails: "saveFastingDetails",
    postFacts: "getMasterFacts",
    postFavorite: "setFavFacts",
    getRecipies: "fetchRecipes",
    fetchAffiliate:"fetchAffiliate",
    sendOTP:"sendOTP",
    verifyOTP:"verifyOTP",
    fetchOrder:"fetchOrders",
    createOrder:"createOrUpdateOrder",
    storeSurveyResponse:"storeSurveyResponse",
  
    /* Seprate Apis to save data in DB */
    saveFasting:"saveFasting",
    getFasting:"getFasting"
  
}

export const CONSTANTS = {
    islocal: true,
    url: "./assets/apis/Master.json",
    isPlanActiveParent: false,
    isDetox: false,
    profile: {
      freePlanExpiryDateTime: "",
      planType: "",
      isPaymentCancelOptionAvailable: false,
    },
    userDetails: {},
    dietDate: "",
    dietDateforHome: "",
    country: "",
    location_country: "India",
    email: "",
    calBurnedToday: -1,
    selectedDietPlan: "weightLoss",
    selectedPlanThemeColor: "#01A3A4", //#01A3A4
  
    defaultCalories: 0,
    Refund_policy: true,
    Diet_plan_open: true,
    isRandomLock: false,
    // image_URL:'http://test.fightitaway.com/images/',
    // wellness_image_URL:'http://test.fightitaway.com/wellness/',
    image_URL: "https://app.smartdietplanner.com/images/",
    wellness_image_URL: "https://app.smartdietplanner.com/wellness/",
    isTestEnv: false,
    isNewAPIs: true,
    encryptKey: "base64:13rCQCD8hoWXg47PhGA4y4/hCkiIOH7gRVN3SbDL7ZM=",
    postImageBaseURL: "https://smartdietplanner.com/wp-content/uploads/",
  };
  //"http://ec2-34-209-64-202.us-west-2.compute.amazonaws.com/api/"
  export const APIS = {
    BASEURL: "https://app.smartdietplanner.com/api/", //
    refreshBaseUrl: "https://app.smartdietplanner.com:8443/api/",
    //  BASEURL: "https://test.fightitaway.com/api/", //https://app.smartdietplanner.com
    //  refreshBaseUrl: "https://test.fightitaway.com:8443/api/",
    WP_BASEURL: "https://smartdietplanner.com/wp-json/wp/v2/posts",
    defaultDetail: "defaultDetail",
    profile: "profile",
    removeAccount: "customer/profile/remove",
    updateProfile: "updateProfile",
    updateDemographic: "updateDemographic",
    updateLifeStyle: "updateLifeStyle",
    updateDiet: "updateDiet",
    dietplan: "update/cust/dietplan",
    authUrl: "authenticate?access_token=",
    loginMethod: "login",
    dietPlans: "v3/dietPlans",
    dietPlansDirect: "dietPlans",
    dietCovidPlans: "v2/dietPlans",
    getOnePlan: "customer/getOnePlan",
    habitMaster: "habitMaster",
    customerHabit: "customer/habits",
    createHabit: "customer/createHabit",
    updateHabit: "customer/updateHabit",
    deleteHabits: "customer/deleteCustomerHabit",
    getHabitsForUpdate: "customer/habitsForUpdate",
    gerWeightGraph: "customer/weightGraphData",
    optionsData: "v2/dietPlans/options",
    optionsDataNew: "dietPlans/options",
    optionSelection: "customer/v2/addDietPref",
    optionSelectionNew: "customer/addDietPref",
    payment: "payment",
    getCouponList: "getCouponList",
    getSdpClientTestimony: "getSdpClientTestimony",
    subscribePlanByCoupon: "customer/subscribePlanByCoupon",
    paymentConfirm: "payment/confirm",
    removePlan: "removePlan",
    updateWeight: "customer/updateWeight",
    googleFit: "https://www.googleapis.com/fitness/v1/users/",
    sendMail: "customer/sendEmail",
    addTnC: "addTnC",
    refresh: "dietPlan/refresh/option",
    refreshInternational: "dietPlans/refresh/options",
    fetchFood: "fetch/food",
    dietPlanTiming: "save/dietPlan/timings",
    fetchDietPlans: "fetch/dietplans",
    fetchHelp: "fetch/help",
    getQA: "fetchAllQnA ",
    detoxStatus: "saveupdate/detox/dietPlan/status",
    updateExpiryDate: "update/payment/details",
    updateTargetWeight: "update/target/weight",
    updateCurrentWeigt: "update/current/weight",
    timings: "fetch/dietPlan/timings",
    getCouponListOffered: "getCouponList?isOffered=true",
    getRecipeOfTheDay: "fetch/countrywise/food/items?country=IND",
    lessThan100CaloriesFoodItem: "fetch/low/calories/foods",
    highProteinFoodItem: "fetch/high/protein/foods",
    healthyChoicesFoodItem: "fetch/healthy/choices",
    lessThan100SlotsFoodItem: "fetch/cust/diet/details",
    lessThan100SlotsFoodItemNew: "fetchCustDietDetails",
    updateCustDietPlan: "update/cust/dietplan",
    referralCode: "fetch/referral/email", //?code=RHsNM
    referralUser: "save/cust/payment/referral/details",
    updateReferralUser: "update/cust/payment/referral/details",
    referralUserTransactions: "fetch/cust/payment/referral/transactions",
    ratings: "save/cust/ratings",
    getDietPreference: "getDietPreference",
    updateDietPref: "updateDietPref",
    fetchTodoList: "fetchTodoList",
    saveOrUpdateCustDailyTodo: "saveOrUpdateCustDailyTodo",
    saveHotLeads: "saveHotLeads",
    deleteHotLeads: "deleteHotLeads",
    getFoodPrefHistory: "getFoodPrefHistory",
    searchFoodItemByName: "searchFoodItemByName",
    updateDietPlan: "updateDietPlan",
    updateEatenFoodItems: "updateEatenFoodItems",
    postSaveFastingDetails: "saveFastingDetails",
    // postFacts: "getMasterFacts",
    postFacts: "fetchAllFacts",
    postFavorite: "setFavFacts",
    getRecipies: "fetchRecipes",
    fetchAffiliate: "fetchAffiliate",
    sendOTP: "sendOTP",
    verifyOTP: "verifyOTP",
    fetchOrder: "fetchOrders",
    createOrder: "createOrUpdateOrder",
    storeSurveyResponse: "storeSurveyResponse",
  
    /* Seprate Apis to save data in DB */
    saveFasting: "saveFasting",
    getFasting: "getFasting",
  
    // end
  };